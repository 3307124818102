<template>
    <div
        :class="['qr-code-container', {'is-blurred blur-exception': !code, 'is-increasable': !isNotIncreasable && code}]"
        :style="`background: ${ colorLight }`"
    >
        <div class="qr-code-content">
            <span class="top-left-angle angle" :style="`border-color: ${ colorDark };`"></span>
            <span class="top-right-angle angle" :style="`border-color: ${ colorDark };`"></span>
            <span class="bottom-left-angle angle" :style="`border-color: ${ colorDark };`"></span>
            <span class="bottom-right-angle angle" :style="`border-color: ${ colorDark };`"></span>
            <div ref="qr-code" id="qr-code"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QRCode',
    props: {
        code: {
            type: [String, Number],
            default: null,
        },
        width: {
            type: Number,
            default: 88,
        },
        height: {
            type: Number,
            default: 88,
        },
        colorDark: {
            type: String,
            default: '#656FDB',
        },
        colorLight: {
            type: String,
            default: 'platform-default',
        },
        correctLevel: {
            type: Number,
            default: 2,
        },
        isNotIncreasable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            qr_code: null,
        };
    },
    computed: {
        mainBackgroundColor() {
            return this.$store.getters.isThemeDark ? '#23232A' : '#f1f2f5';
        },
        codeToGenerate() {
            return this.code ? this.code : 'here will be a code';
        },
    },
    methods: {
        getColorLight(color) {
            return color === 'platform-default' ? this.mainBackgroundColor : color;
        },
        generateQR() {
            const QRCodeContainer = this.$refs['qr-code'];

            if (this.qr_code) {
                this.qr_code.makeCode(this.codeToGenerate);
            } else {
                this.qr_code = new window.QRCode(QRCodeContainer, {
                    text: this.codeToGenerate,
                    width: this.width * (this.isNotIncreasable ? 1 : 2),
                    height: this.height * (this.isNotIncreasable ? 1 : 2),
                    colorDark: this.colorDark,
                    colorLight: this.getColorLight(this.colorLight),
                    correctLevel: this.correctLevel,
                });
            }

            this.$emit('code-ready', this.qr_code);
        },
    },
    mounted() {
        this.generateQR();
    },
    watch: {

        code() {
            this.generateQR();
        },

    },
};
</script>
