<template>
    <div :class="[s.container, theme.profile.profile]">
        <div :class="s.content">
            <p
                @click="$router.push('/profile')"
                :class="s.goBack"
            >
                ← Go back
            </p>
            <p :class="[s.mbS, theme.profile.text, theme.profile.large, theme.profile.extrabold, theme.profile.black]">
                Two Factor Authentication
            </p>
            <div :class="s.module">
                <div :class="s.header">
                    <span :class="[theme.profile.text, theme.profile.extrabold, theme.profile.big, theme.profile.black]">
                        Google Authentication
                    </span>
                </div>
                <div>
                    <ol>
                        <li>
                            <span :class="s.number">
                                1
                            </span>
                            <div>
                                <p :class="[s.mbS, theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.black]">
                                    Install an Authenticator App
                                </p>
                                <p :class="[theme.profile.text, theme.profile.big, theme.profile.regular, theme.profile.gray]">
                                    If you don’t already have an authenticator app, download one from the App Store (iOS) or Google Play Store (Android). Popular choices include Google Authenticator, Microsoft Authenticator, or Authy.
                                </p>
                            </div>
                        </li>
                        <li>
                            <span :class="s.number">
                                2
                            </span>
                            <div>
                                <div :class="s.contentRow">
                                    <div>
                                        <p :class="[s.mbS, theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.black]">
                                            Add Your Account to the Authenticator App
                                        </p>
                                        <p :class="[theme.profile.text, theme.profile.big, theme.profile.regular, theme.profile.gray]">
                                            Open the authenticator app on your device.
                                            If using the QR code: select the Scan QR code option and scan the code on your screen.
                                            If using the setup key: choose the Enter a setup key option, then copy and paste or type in the setup key provided.
                                        </p>
                                    </div>
                                    <div>
                                        <QRCode :code="qrCode" />
                                    </div>
                                </div>
                                <TextInput
                                    title="Secret Key"
                                    :value="secretKey"
                                    readonly
                                    copy
                                    @copy="secretKeyCopied($event)"
                                />
                            </div>
                        </li>
                        <li>
                            <span :class="s.number">
                                3
                            </span>
                            <div>
                                <p :class="[s.mbS, theme.profile.text, theme.profile.big, theme.profile.bold, theme.profile.black]">
                                    Enter the OTP Code:
                                </p>
                                <p :class="[s.mbM, theme.profile.text, theme.profile.big, theme.profile.regular, theme.profile.gray]">
                                    The authenticator app will generate a 6-digit OTP code. Enter this code into the provided field on the setup screen.
                                </p>
                                <form @submit.prevent="confirm" :class="s.contentCol">
                                    <span :class="[s.mbS, theme.profile.text, theme.profile.small, theme.profile.regular, theme.profile.gray]">
                                        Verification Code
                                    </span>
                                    <div :class="[s.row, s.relative]">
                                        <CustomTooltip
                                            v-if="codeError && showCodeError"
                                            content="Enter 6 digits code"
                                            @click="showCodeError = false"
                                        />
                                        <SeparatedDigitsInput
                                            id-prefix="Verification code"
                                            :number-of-digits="6"
                                            :onChange="onChange"
                                            :has-errors="codeError"
                                            :class="s.mbM"
                                        />
                                    </div>
                                    <Button
                                        is-submit
                                        :button-disabled="!verificationCode || isButtonDisabled"
                                        button-type="primary"
                                    >
                                        <template>
                                            Confirm
                                        </template>
                                    </Button>
                                </form>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import theme from 'Theme';
import TextInput from 'Control/TextInput.vue';
import SeparatedDigitsInput from 'Control/SeparatedDigitsInput.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import Button from 'Control/Button.vue';
import QRCode from 'Common/QRCode.vue';
import CustomTooltip from 'UI/CustomTooltip.vue';
import ApiError from 'Entities/ApiError';

export default {
    name: 'SetMFATOTP',
    components: {
        CustomTooltip,
        TextInput,
        SeparatedDigitsInput,
        Button,
        QRCode,
    },
    data() {
        return {
            secretKey: '',
            qrCode: '',
            verificationCode: '',
            isButtonDisabled: false,
            theme,
            codeError: false,
            showCodeError: false,
        };
    },
    computed: {
        ...mapGetters({
            userEmail: 'Auth/profileEmail',
        }),
    },
    methods: {
        generateSecretKey() {
            this.$store.dispatch('Auth/enrollMfa', {
                mfaType: MFA_ENROLL_FACTOR_TYPES.TOTP,
            })
                .then((secretKey) => {
                    this.secretKey = secretKey;
                    this.qrCode = `otpauth://totp/${this.userEmail}?secret=${secretKey}&issuer=Single Broker Platform`;
                }).catch(({ message }) => {
                    this.$store.dispatch('Notificator/showErrorNotification', message);
                });
        },
        secretKeyCopied(value) {
            copyTextWithPermissions(value, this.$store.dispatch, 'Secret');
        },
        async confirm() {
            if (this.verificationCode.length === 6) {
                this.isButtonDisabled = true;
                try {
                    await this.$store.dispatch('Auth/activateMfa', {
                        mfaType: MFA_ENROLL_FACTOR_TYPES.TOTP,
                        passCode: this.verificationCode,
                    });
                    await this.$store.dispatch('Notificator/showSuccessNotification', 'MFA method is successfully updated');
                    await this.$router.push('/profile');
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                    }
                    this.isButtonDisabled = false;
                }
            } else {
                this.codeError = true;
            }
        },
        onChange(value) {
            this.verificationCode = value;
            this.codeError = false;
        },
    },
    mounted() {
        this.generateSecretKey();
    },
    watch: {
        codeError(val) {
            this.showCodeError = val;
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    & .content {
        width: 550px;
        & .module {
            background: var(--cl-white);
            border-radius: 8px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            box-shadow: 0 0 10px var(--cl-white);
            & .header {
                padding: var(--m-m);
                border-bottom: 1px solid var(--cl-gray-light);
            }
            & ol {
                width: 100%;
                & li {
                    display: flex;
                    padding: var(--m-m);
                    column-gap: var(--m-l);
                    border-bottom: 1px solid var(--cl-gray-light);
                    &:last-child {
                        border-bottom: none;
                    }
                    & .number {
                        min-height: 32px;
                        min-width: 32px;
                        max-height: 32px;
                        max-width: 32px;
                        text-align: center;
                        padding-top: var(--m-s);
                        background-color: var(--cl-violet-extra-light);
                        border-radius: 50%;
                        font-weight: var(--fw-extrabold);
                        font-size: var(--fs-xl);
                        line-height: var(--fs-xl);
                        color: var(--cl-violet);
                    }
                }
            }
        }
    }
    & .goBack {
        font-weight: var(--fw-bold);
        font-size: var(--fs-l);
        line-height: var(--fs-l);
        color: var(--cl-violet);
        cursor: pointer;
        margin-bottom: var(--m-s);
    }
    & .mbS {
        margin-bottom: var(--m-s);
    }
    & .mbM {
        margin-bottom: var(--m-m);
    }
    .row {
        display: flex;
    }
    .contentRow {
        display: flex;
        column-gap: var(--m-l);
        margin-bottom: var(--m-m);
    }
    .contentCol {
        display: flex;
        flex-direction: column;
        width: min-content;
    }
    .relative {
        position: relative;
    }
}
</style>
